import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import project1 from '../../assets/images/projects/project1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import project3 from '../../assets/images/projects/project3.jpg'
import project4 from '../../assets/images/projects/project4.jpg'
import project5 from '../../assets/images/projects/project5.jpg'
import project6 from '../../assets/images/projects/project6.jpg'

const RecentProjects = () => {
    return (
        <section className="projects-area bg-color pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Recent Projects
                    </span>
                    <h2>Check Some Of Our Recent Work</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                    <p>Check out our recent work, please click to see more details</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project1} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    <a href="https://phonebounce.com/" target={"_blank"}>
                                    Phonebounce
                                    </a>
                                </h3>
                                <span>Complete Streaming Web Application</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project2} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    <a href="https://musicart.io/" target={"_blank"}>
                                    Musicart
                                    </a>
                                </h3>
                                <span>NFT Marketplace</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project3} alt="project" />
{/* 
                                <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    <a href="http://www.smartbooks.sk/" target={"_blank"}>
                                    Smartbooks
                                    </a>
                                </h3>
                                <span>Complete LMS for Children</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project4} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    <a href="https://member.cash/index.html" target={"_blank"}>
                                    Member.cash
                                    </a>
                                </h3>
                                <span>Programming</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={project5} alt="project" />

                                {/* <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link> */}
                            </div>

                            <div className="content">
                                <h3>
                                    <a href="http://velocity-global.com/mobile-app.php" target={"_blank"}>
                                    Velocity Global
                                    </a>
                                </h3>
                                <span>Mobile Application</span>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="col-lg-4 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project6} alt="project" />

                                <Link className="link-btn" to="/case-studies-details">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <a to="https://play.google.com/store/apps/details?id=com.pinetechnologies.ehisaab&hl=en_US&gl=US" target={"_blank"}>
                                    eHisaab
                                    </a>
                                </h3>
                                <span>Mobile Application</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default RecentProjects;