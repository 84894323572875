import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" />
                        Our History
                    </span>
                    {/* <h2>We Different From Others Should Choose Us</h2> */}
                    <h2>Who We are</h2>
                    {/* <p>Because since the year 2018, For a large number of clients. We help organisations transition by providing powerful and adaptive digital solutions that meet today's needs while also enabling tomorrow's prospects.</p> */}
                    <p>NC North Codex software firm is established in 2020. The journey was started initially its limited resources from a home based since then we have expended to offer a full range software house. We first specialize software and develop projects as per clients demands to further enhance their business operations.
                        A highly dedicated, trained and certified teams, is hosting this company in Gilgit-Baltistan Pakistan, which has core objective to enhance Digital marketing, enterprise development with effective delivery services as well.
                    </p>
                </div>

                {/* <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Code Security
                                    Design and Development
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>

                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Digital marketing
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>

                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Access Controlled
                                    Mobile App Development
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>

                            <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default OurSolutions;