import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import bannerImg from "../../assets/images/banner-img1.png"

const Banner = () => {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>North Codex Secure IT Solutions </h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                  "North Codex," as the name suggests, is a tech-based company
                  that aims to work in the freelance marketplace and community
                  development. Our diverse team envisions a digital future in
                  the fields of software development, mobile applications,
                  digital marketing, affiliate marketing, sass products, and
                  supporting indigenous art, music, and folklore. 
                </p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to="/contact" className="default-btn">
                    <i className="flaticon-right"></i>
                    LET'S TALK <span></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <img src={bannerImg} alt="banner" />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
