import React, { useState } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
import serviceIcon1 from "../../assets/images/services/service-icon1.png"
import serviceIcon2 from "../../assets/images/services/service-icon2.png"
import serviceIcon3 from "../../assets/images/services/service-icon3.png"
import serviceIcon4 from "../../assets/images/services/service-icon4.png"
import serviceIcon5 from "../../assets/images/services/service-icon5.png"
import serviceIcon6 from "../../assets/images/services/service-icon6.png"

const OurFeatures = () => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="feature" />
            {/* Our Features */}
            Our Services
          </span>

          <h2>We’re Here To Help</h2>
          {/* <p>We believe in
                  empowering youth in the IT sector by building and sharing a
                  learning environment through skill-based training programmes. Our team can assist you in transforming your business with the latest tech capabilities to stay ahead of the curve. </p> */}
          <p>
            This software house was design and focus to fulfill our clients
            essential as they deserve, whether they’re looking ways to make
            digital automated and systemic application that they want to enter
            in global digital market. We‘re not just programmers but also
            friendly digital partners. Our professional team focused to deliver
            effective services as always.
          </p>
        </div>

        <div className="row">

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box">
              <div className="icon">
                <img src={serviceIcon2} alt="feature" />
              </div>
              {/* <h3>Email Notifications</h3> */}
              <h3>FULL STACK DEVELOPMENT</h3>
              <p>
                We are offering to client's to involve web developer front and
                back end of websites application databases building user-facing
                websites to during the planning phase of projects
              </p>
              <Link
                to="/service-details"
                className="view-details-btn"
                state={{ service: "Full-Stack Development" }}
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box">
              <div className="icon">
                <img src={serviceIcon3} alt="feature" />
              </div>
              {/* <h3>Simple Dashboard</h3> */}
              <h3>Mobile App Development</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
              <p>
                Anytime, anywhere mobile solutions for small to medium-sized
                businesses. North Codex delivers innovative, affordable mobile
                application development services. Regardless of device or
                platform.
              </p>
              <Link to="/service-details" className="view-details-btn" state={{ service: "Mobile Application" }}>
                View Details
              </Link>
            </div>
          </div>


          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box">
              <div className="icon">
                <img src={serviceIcon6} alt="feature" />
              </div>
              {/* <h3>Deadline Reminders</h3> */}
              <h3>Web Developement</h3>
              <p>
                We help our clients to translate there bussines into modern and
                user friendly web apps that help them grow there bussines
              </p>
              <Link to="/service-details" className="view-details-btn" state={{ service: "Web Developement"}}>
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box">
              <div className="icon">
                <img src={serviceIcon1} alt="feature" />
              </div>
              {/* <h3>Incredible Infrastructure</h3> */}
              <h3>UX/UI Design</h3>

              <p>
                NC North Codex mainly working on design and development,
                Graphic, Mobile app development digital marketing and many more.
              </p>
              <Link
                to="/service-details"
                className="view-details-btn"
                // onClick={handleNaviagation}
                state={{ service: "UX/UI Design" }}
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box ">
              <div className="icon">
                <img src={serviceIcon4} alt="feature" />
              </div>
              {/* <h3>Information Retrieval</h3> */}
              <h3>SEO</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
              <p>
                Our SEO experts will use different SEO tools and identify
                mistakes and errors and would guide you step by step how to
                perform search engine optimization, We provides the best seo
                services{" "}
              </p>
              <Link to="/service-details" className="view-details-btn" state={{ service: "SEO"}}>
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-services-item-box single-solutions-box">
              <div className="icon">
                <img src={serviceIcon5} alt="feature" />
              </div>
              {/* <h3>Drag and Drop</h3> */}
              <h3>Digital Marketing</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
              <p>
                We give you the best digital marketing experience, our focus is
                to increase your ROI. We analyze competitior strategies and we
                make our own strateigies to boost your sales via digital
                marketing.
              </p>
              <Link to="/service-details" className="view-details-btn" state={{ service: "Digital Marketing"}}>
                View Details
              </Link>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default OurFeatures
